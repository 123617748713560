:root {
    /* Colors */
    --color-primary: #3498db;
    --color-primary-hover: #2980b9;
    --color-secondary: #2c3e50;
    --color-background: #f4f4f4;
    --color-text: #333333;
    --color-text-light: #666666;
    --color-border: #e2e8f0;
    
    /* Spacing */
    --spacing-xs: 0.25rem;
    --spacing-sm: 0.5rem;
    --spacing-md: 1rem;
    --spacing-lg: 2rem;
    --spacing-xl: 4rem;
    
    /* Typography */
    --font-family-base: 'Inter', sans-serif;
    --font-size-base: 16px;
    --font-size-sm: 0.875rem;
    --font-size-lg: 1.125rem;
    --font-size-xl: 1.25rem;
    --font-size-2xl: 1.5rem;
    --font-size-3xl: 2rem;
    
    /* Border Radius */
    --border-radius-sm: 0.25rem;
    --border-radius-md: 0.5rem;
    --border-radius-lg: 1rem;
    --border-radius-full: 9999px;
    
    /* Transitions */
    --transition-base: 0.3s ease-in-out;
  }