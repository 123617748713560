body {
    font-family: var(--font-family-base);
    font-size: var(--font-size-base);
    line-height: 1.5;
    color: var(--color-text);
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin-bottom: var(--spacing-md);
    font-weight: 700;
    line-height: 1.2;
  }
  
  .page-title {
    font-size: var(--font-size-3xl);
    margin-bottom: var(--spacing-lg);
    text-align: center;
  }
  
  .section-title {
    font-size: var(--font-size-2xl);
    margin-bottom: var(--spacing-md);
  }