.button-base {
    display: inline-block;
    padding: var(--spacing-sm) var(--spacing-lg);
    border-radius: var(--border-radius-md);
    transition: var(--transition-base);
    text-align: center;
    cursor: pointer;
  }
  
  .cta-button {
    @apply button-base;
    background-color: var(--color-primary);
    color: white;
  }
  
  .cta-button:hover {
    background-color: var(--color-primary-hover);
  }
  
  .submit-button {
    @apply button-base;
    width: 100%;
    background-color: var(--color-primary);
    color: white;
  }
  
  .submit-button:hover {
    background-color: var(--color-primary-hover);
  }