.form-group {
    margin-bottom: var(--spacing-md);
  }
  
  .form-label {
    display: block;
    margin-bottom: var(--spacing-sm);
    font-weight: 600;
  }
  
  .form-input,
  .form-textarea {
    width: 100%;
    padding: var(--spacing-sm);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-sm);
    transition: var(--transition-base);
  }
  
  .form-input:focus,
  .form-textarea:focus {
    outline: none;
    border-color: var(--color-primary);
  }
  
  .form-textarea {
    min-height: 120px;
    resize: vertical;
  }