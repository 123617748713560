/* Contact Container */
.contact {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  /* Contact Grid Layout */
  .contact-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;
  }
  
  /* Contact Information Section */
  .contact-info {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .contact-heading {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .contact-subheading {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 2rem;
  }
  
  /* Contact Details */
  .contact-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .contact-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    transition: transform 0.2s ease;
  }
  
  .contact-item:hover {
    transform: translateX(5px);
  }
  
  .contact-icon {
    width: 24px;
    height: 24px;
    color: #666;
  }
  
  .contact-text {
    color: #333;
  }
  
  /* Contact Form */
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .form-label {
    font-weight: 500;
    color: #333;
  }
  
  .form-input,
  .form-textarea {
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.2s ease;
  }
  
  .form-input:focus,
  .form-textarea:focus {
    border-color: #666;
    outline: none;
  }
  
  .form-textarea {
    min-height: 150px;
    resize: vertical;
  }
  
  /* Form Validation Styles */
  .form-input.error,
  .form-textarea.error {
    border-color: #dc3545;
  }
  
  .error-message {
    color: #dc3545;
    font-size: 0.875rem;
    margin-top: 0.25rem;
  }
  
  /* Submit Button */
  .submit-button {
    padding: 1rem 2rem;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .submit-button:hover {
    background-color: #444;
  }
  
  .submit-button:disabled {
    background-color: #999;
    cursor: not-allowed;
  }
  
  /* Success Message */
  .success-message {
    padding: 1rem;
    background-color: #d4edda;
    color: #155724;
    border-radius: 4px;
    margin-top: 1rem;
  }
  
  /* Contact Cards */
  .contact-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
  }
  
  .contact-card {
    padding: 1.5rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .contact-card:hover {
    transform: translateY(-5px);
  }
  
  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .card-subtitle {
    color: #666;
    margin-bottom: 1rem;
  }
  
  /* Social Links */
  .social-links {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }
  
  .social-link {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
    border-radius: 50%;
    color: #666;
    transition: all 0.2s ease;
  }
  
  .social-link:hover {
    background-color: #333;
    color: white;
  }
  
  /* Map Container */
  .map-container {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 2rem;
  }
  
  /* Dark Theme */
  .contact-dark {
    background-color: #333;
    color: white;
  }
  
  .contact-dark .contact-heading {
    color: white;
  }
  
  .contact-dark .contact-subheading {
    color: #ddd;
  }
  
  .contact-dark .contact-item {
    background-color: #444;
  }
  
  .contact-dark .contact-icon,
  .contact-dark .contact-text {
    color: white;
  }
  
  .contact-dark .form-label {
    color: white;
  }
  
  .contact-dark .form-input,
  .contact-dark .form-textarea {
    background-color: #444;
    border-color: #555;
    color: white;
  }
  
  .contact-dark .submit-button {
    background-color: #666;
  }
  
  .contact-dark .submit-button:hover {
    background-color: #777;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .contact-grid {
      grid-template-columns: 1fr;
    }
  
    .contact-cards {
      grid-template-columns: 1fr;
    }
    
    .contact {
      padding: 1rem;
    }
    
    .contact-heading {
      font-size: 1.5rem;
    }
  }
  
  /* Loading State */
  .loading {
    position: relative;
    opacity: 0.7;
    pointer-events: none;
  }
  
  .loading::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    margin: -12px;
    border: 2px solid #333;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Required Field Indicator */
  .required::after {
    content: "*";
    color: #dc3545;
    margin-left: 4px;
  }
  
  /* Custom Checkbox and Radio Styles */
  .form-checkbox,
  .form-radio {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }
  
  .form-checkbox input[type="checkbox"],
  .form-radio input[type="radio"] {
    width: 18px;
    height: 18px;
  }