.site-header {
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .main-navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-md) 0;
  }
  
  .nav-links {
    display: flex;
    gap: var(--spacing-lg);
  }
  
  .nav-link {
    color: var(--color-text);
    transition: var(--transition-base);
  }
  
  .nav-link:hover {
    color: var(--color-primary);
  }