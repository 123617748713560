.hero-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-xl);
    margin-bottom: var(--spacing-xl);
  }
  
  @media (min-width: 768px) {
    .hero-section {
      flex-direction: row;
      justify-content: center;
    }
  }
  
  .profile-image-container {
    width: 100%;
    height: auto;
  }
  
  .profile-image {
    width: 256px;
    height: 256px;
    border-radius: var(--border-radius-full);
    object-fit: cover;
    border: 4px solid var(--color-primary);
    display: block;
  }
  
  .hero-content {
    max-width: 600px;
  }
  
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: var(--spacing-md);
  }