/* components/navigation.css */
.nav {
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .nav-fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  .nav-logo {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    color: #333333;
  }
  
  .nav-menu {
    display: flex;
    margin-left: auto;
    list-style: none;
    gap: 2rem;
  }
  
  .nav-link {
    text-decoration: none;
    color: #666666;
    transition: color 0.2s ease;
  }
  
  .nav-link:hover {
    color: #333333;
  }
  
  /* Mobile navigation */
  @media (max-width: 768px) {
    .nav-menu {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      background-color: #ffffff;
      padding: 1rem;
      flex-direction: column;
      gap: 1rem;
    }
  
    .nav-menu.active {
      display: flex;
    }
  
    .nav-toggle {
      display: block;
      margin-left: auto;
    }
  }