.projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: var(--spacing-lg);
  }
  
  .technology-tag {
    display: inline-block;
    padding: var(--spacing-xs) var(--spacing-sm);
    margin: 0 var(--spacing-xs) var(--spacing-xs) 0;
    background-color: var(--color-background);
    border-radius: var(--border-radius-full);
    font-size: var(--font-size-sm);
  }
  
  .project-title {
    font-size: var(--font-size-xl);
    margin-bottom: var(--spacing-sm);
  }
  
  .project-description {
    color: var(--color-text-light);
    margin-bottom: var(--spacing-md);
  }