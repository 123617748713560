.card-base {
    background-color: white;
    border-radius: var(--border-radius-md);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: var(--transition-base);
  }
  
  .skill-item {
    @apply card-base;
    padding: var(--spacing-md);
    text-align: center;
  }
  
  .project-card {
    @apply card-base;
    padding: var(--spacing-lg);
    transition: transform var(--transition-base);
  }
  
  .project-card:hover {
    transform: translateY(-4px);
  }